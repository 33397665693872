<template>
	<section class="activeSponsorsSection" id="activeSponsorsSection">
		<h1>Sponsors</h1>
		<div class="activeSponsorsContainer">
			<div class="diamond-plus">
				<h3>Diamond Plus</h3>
				<div>
					<a target="_blank" href="https://jasminer.com/">
						<img src="@/assets/images/JASMINER_logo.png" alt="Jasminer" />
					</a>
			
					
				</div>
			</div>

			<div class="platinum">
				<h3>Platinum</h3>
				<div>
					<a target="_blank" href="https://etcgrantsdao.io/">
						<img src="@/assets/images/edg-logo-green-with-text.abb09187-2.png" alt="ETC Grants DAO" />
					</a>


					<a target="_blank" href="https://hivedigitaltechnologies.com/">
						<img src="@/assets/images/hive-digital.png" alt="HIVE Digital Technologies" />
					</a>

					<a target="_blank" href="https://www.bitmain.com/">
						<img src="@/assets/images/Bitmain-logo.png" alt="Bitmain" />
					</a>
				</div>
			</div>

			<div class="gold">
				<h3>Gold</h3>
				<div>
					<a target="_blank" href="https://www.lokotech.no/">
						<img src="@/assets/images/lokotech.png" alt="Lokotech" />
					</a>
				</div>
			</div>

			<div class="silver">
				<h3>Silver</h3>
				<div>
					<a target="_blank" href="https://lumerin.io/">
						<img src="@/assets/images/lumerin.png" alt="Lumerin" />
					</a>
					<a target="_blank" href="https://ipollo.com/">
						<img src="@/assets/images/ipollo.png" alt="iPollo" />
					</a>
					<a target="_blank" href="https://ballet.com/">
						<img src="@/assets/images/ballet.png" alt="Ballet" />
					</a>
				</div>
			</div>
		</div>
	</section>
</template>
