<template>
	<slot name="header"></slot>

	<slot name="main"></slot>
</template>

<script>
export default {
	name: "Layout",
};
</script>
