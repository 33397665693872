<template>
	<div class="proofOfWorkMainContainer" id="proofOfWorkMainContainer">
		<div class="proofOfWorkContainer">
			<div class="proofMainContainer">
				<div class="proofOfWorkImg">
					<img src="@/assets/images/proof-of-work-2024.png" alt="" />
				</div>
			</div>
		</div>
	</div>
	<div class="proofMainContainer proofHeroCards">
		<div class="proofHeroHead">
			<h2>THE “BITCOIN RENAISSANCE”</h2>
<iframe class="youtube-video" src="https://www.youtube.com/embed/shcr-qyqUF8?si=wz2XuxHy2jSzGazc?fs=0&modestbranding=1&playsinline=1&rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
			<p>
The broad set of use cases becoming available on Bitcoin's base layer and the competitive landscape of scaling projects are an exhilarating validation of POW. Join us in exploring the cypherpunk fundamentals and value propositions of the major POW chains in four thematic streams:
			</p>
		</div>

		<div class="row">
			<div class="col-lg-3 col-md-3 col-sm-6 col-12 px-md-3">
				<div class="heroCard">
					<div class="heroCardImg">
						<img src="@/assets/images/heroImg1.png" alt="" />
					</div>
					<div class="heroCardContent">
						<h3>Proof of Scale </h3>
						<p>
							Join industry veterans and newcomers in discussing competing solutions for technical and economic tradeoffs in the hottest sector of development and capital investment.


						</p>
					</div>
				</div>
			</div>
			<div class="col-lg-3 col-md-3 col-sm-6 col-12 px-md-3">
				<div class="heroCard">
					<div class="heroCardImg">
						<img src="@/assets/images/heroImg2.png" alt="" />
					</div>
					<div class="heroCardContent">
						<h3>Proof of Utility </h3>
						<p>
							Learn about the emerging protocols and diverse use cases competing for scarce block space on the base layer and L2s.

 
						</p>
					</div>
				</div>
			</div>
        <div class="col-lg-3 col-md-3 col-sm-6 col-12 px-md-3">
				<div class="heroCard">
					<div class="heroCardImg">
						<img src="@/assets/images/heroImg3.png" alt="" />
					</div>
					<div class="heroCardContent">
						<h3>Proof of Sustainability </h3>
						<p>
							Discover innovative miner strategies for environmentally sustainable solutions driving technology forward in the face of hostile governments and regulators.

 
						</p>
					</div>
				</div>
			</div>
			<div class="col-lg-3 col-md-3 col-sm-6 col-12 px-md-3">
				<div class="heroCard">
					<div class="heroCardImg">
						<img src="@/assets/images/heroImg4.png" alt="" />
					</div>
					<div class="heroCardContent">
						<h3>Proof of Human Rights </h3>
						<p>
							Recent prosecutorial and legislative actions in the US and Europe have made the credo "Cypherpunks write code" to secure privacy, fungibility, and autonomy more important than ever. 
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
