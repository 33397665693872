<template>
	<section class="scheduleHomeSection" id="registerSpeakerSection">
		<div class="proofMainContainer registerSpeakerContainer">
			<a
				href="https://cfp.powsummit.com/2024/schedule/" 
				target="_blank"
				class="text-decoration-none"
			>
				<button>Event Schedule</button>
			</a>
		</div>
	</section>
</template>
<script>
import { getButton } from "@/config/2024/buttons.js";
export default {
	data() {
	},
};
</script>
