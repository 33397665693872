<template>
	<!-- hero section with navbar starts here -->
	<section class="proofHeroSecion">
		<nav class="navbar navbar-expand-lg proofNav">
			<div class="container-fluid">
				<button
					class="navbar-toggler order-lg-1 order-1"
					type="button"
					data-bs-toggle="collapse"
					data-bs-target="#navbarSupportedContent"
					aria-controls="navbarSupportedContent"
					aria-expanded="false"
					aria-label="Toggle navigation"
				>
					<span class="navbar-toggler-icon"></span>
				</button>
				<div
					class="collapse navbar-collapse order-lg-2 order-3"
					id="navbarSupportedContent"
				>
					<ul class="navbar-nav me-auto mb-2 mb-lg-0">
						<li class="nav-item">
							<a class="nav-link active" aria-current="page" href="/"
								>POW Summit</a
							>
						</li>
					</ul>
				</div>
				<a
					href="https://powsummit2024.eventbrite.ca/"
					class="getTicketsBtn order-lg-3 order-2"
					>GET TICKETS</a
				>
			</div>
		</nav>
	</section>
	<section class="p-5">
		<div class="container">
			<div class="row">
				<div class="col-sm-0 col-md-2 col-lg-2"></div>
				<div class="col-sm-12 col-md-8 col-lg-8">
					<h1 class="pb-4">COOKIE POLICY OF POWSUMMIT.COM</h1>

					<h2>1. Introduction</h2>

					<p>
						Our website, powsummit.com (hereinafter: "the website") uses
						cookies and similar technologies (for convenience, all such
						technologies are referred to as "cookies"). Cookies are also placed
						by third parties we have engaged. In the document below, we inform
						you about the use of cookies on our website.
					</p>

					<h2>2. What are cookies?</h2>

					<p>
						A cookie is a small simple file that is sent along with pages of
						this website and stored by your browser on the hard drive of your
						computer or another device. The information stored therein may be
						returned to our servers or to the servers of the relevant third
						parties during a subsequent visit.
					</p>

					<h2>3. What are scripts?</h2>

					<p>
						A script is a piece of program code that is used to make our website
						function properly and interactively. This code is executed on our
						server or on your device.
					</p>

					<h2>4. Consent</h2>

					<p>
						When you visit our website for the first time, we will show you a
						pop-up with an explanation about cookies. As soon as you click on
						"All cookies", you consent to us using all cookies and plug-ins as
						described in the pop-up and this cookie statement. You can disable
						the use of cookies via your browser, but please note that our
						website may no longer work properly.
					</p>

					<h2>5. Third parties</h2>

					<p>
						We have made agreements about the use of cookies with other
						companies that place cookies. However, we cannot guarantee that
						these third parties handle your personal data in a reliable or
						secure manner. Parties such as Google are to be considered as
						independent data controllers. We recommend that you read the privacy
						statements of these companies.
					</p>

					<h2>6. Cookies</h2>

					<p>6.1 Technical or functional cookies</p>

					<p>
						Some cookies ensure that certain parts of the website work properly
						and that your user preferences remain known. By placing functional
						cookies, we make it easier for you to visit our website.
					</p>

					<p>6.2 Analytical cookies</p>

					<p>
						We use analytical cookies to optimize the website experience for our
						users. With these analytical cookies, we get insights into the usage
						of our website.
					</p>

					<h2>7. Enabling/disabling and deleting cookies</h2>

					<p>
						You can use your internet browser to automatically or manually
						delete cookies. You can also specify that certain cookies may not be
						placed. Another option is to change the settings of your internet
						browser so that you receive a message each time a cookie is placed.
						For more information about these options, please refer to the
						instructions in the Help section of your browser.
					</p>

					<p>8. Contact details</p>

					<p>
						For questions and/or comments about our cookie policy and this
						statement, please contact us using the following contact details:
					</p>

					<p><b>Ethereum Classic Cooperative Inc</b></p>
					<p><b>1207 Delaware Ave #541</b></p>
					<p><b>Wilmington, DE 19806</b></p>
					<p><b>USA</b></p>
				</div>
				<div class="col-sm-0 col-md-2 col-lg-2"></div>
			</div>
		</div>
	</section>
	<footer class="proofFooter">
		<div class="footerContainer">
			<ul class="footerLinks">
				<li>
					<a href="/">POW Summit</a>
				</li>
			</ul>
			<h1>BROUGHT TO YOU BY</h1>
			<div class="footerLogo">
				<a href="https://etccooperative.org">
					<img src="@/assets/images/footer-logo2.png" alt="" />
				</a>
				<a href="https://litecoin.net">
					<img src="@/assets/images/footer-logo1.png" alt="" />
				</a>
			</div>
		</div>
	</footer>
</template>
