<template>
	<footer class="proofFooter">
		<div class="footerContainer">
			<ul class="footerLinks">
				<li>
					<router-link active-class="active" to="/">Home</router-link>
				</li>
				<li>
					<a href="2024/#ourSpeakersSection">Speakers</a>
				</li>
				<li>
					<a href="/2024/#getTicketSection">Tickets</a>
				</li>
				<li>
					<router-link to="/photos">Photos</router-link>
				</li>
				<li>
					<router-link to="/videos">Videos</router-link>
				</li>
			</ul>
			<h1>BROUGHT TO YOU BY</h1>

			<div class="footerLogo">
				<a href="https://etccooperative.org">
					<img src="@/assets/images/footer-logo2.png" alt="" />
				</a>
				<a href="https://litecoin.net">
					<img src="@/assets/images/footer-logo1.png" alt="" />
				</a>
			</div>
			<div class="socialMediaIcons">
		
				<a href="https://twitter.com/ETCCooperative">
					<img src="@/assets/images/twitter.svg" alt="" />
					@ETCCooperative
				</a>
				<a href="https://twitter.com/LTCFoundation">
					<img src="@/assets/images/twitter.svg" alt="" />
					@LTCFoundation
				</a>

				<a href="https://www.linkedin.com/company/etc-cooperative/">
					<img src="@/assets/images/linkedin.svg" alt="" />
					ETC Cooperative
				</a>
				<a href="https://www.linkedin.com/company/litecoin-foundation/">
					<img src="@/assets/images/linkedin.svg" alt="" />
					Litecoin Foundation
				</a>
				<a href="https://www.instagram.com/etccooperative/">
					<img src="@/assets/images/instagram.svg" alt="" />
					@ETCCooperative
				</a>
				<a href="https://www.facebook.com/etccooperative2023/">
					<img src="@/assets/images/facebook.svg" alt="" />
					ETC Cooperative Facebook
				</a>
			</div>
<h1>POW SOCIAL ACCOUNTS</h1>
<div class="socialMediaIcons">
				<a href="https://twitter.com/PowSummit">
					<img src="@/assets/images/twitter.svg" alt="" />
					POW Twitter
				</a>
				<a href="https://www.youtube.com/channel/UCvaKOsLxVrNeX8oUYOuua4g">
					<img src="@/assets/images/youtube.svg" alt="" />
					POW YouTube
				</a>
				<a href="https://www.facebook.com/profile.php?id=61553933439760">
					<img src="@/assets/images/facebook.svg" alt="" />
					POW Facebook
				</a>

				<a href="https://www.linkedin.com/company/proof-of-work-summit/">
					<img src="@/assets/images/linkedin.svg" alt="" />
					POW LinkedIn
				</a>
				<a href="https://www.instagram.com/powsummit/">
					<img src="@/assets/images/instagram.svg" alt="" />
					POW Instagram
				</a>
			</div>
		</div>
	</footer>
</template>
